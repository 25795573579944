<template>
  <div class="new-landing" v-if="page">

    <div class="verify-overlay fixed z-100 bg-black bg-opacity-90 left-0 top-0 right-0 bottom-0 flex flex-row items-center p-8" v-if="$route.path === '/verify-email' && !verifyClosed">
      <div class="verify-inner bg-black p-16 max-w-screen-s m-auto relative border border-white border-opacity-30">
        <button type="button" class="close-button focus:outline-none w-16 h-16 absolute right-0 top-0 absolute text-center" @click="verifyClosed = true">
          <svg width="20" height="20" aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" class="block m-auto svg-inline--fa fa-times fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>
        </button>
        <div v-if="!resent">
          Thank you for registering, please check your email.<br><br>
          If you didn't receive your verification Email click <a href="#" @click.prevent="resendVerificationEmail">here</a> to re-send, or contact us <a href="https://app.smartsheet.com/b/form/0f566bc5273f45248c8ed20b7808dd3e" target="_blank">here</a>
        </div>
        <div v-if="resent">
          We have re-sent your verification email. If you are still having trouble please contact us <a href="https://app.smartsheet.com/b/form/0f566bc5273f45248c8ed20b7808dd3e" target="_blank">here</a>
        </div>
        <div v-if="responseMessage">
          <br><br>
          {{responseMessage}}
        </div>
      </div>
    </div>

    <div class="section-container">
      <div class="section section1">
        <div class="outer-container">
          <div class="inner-container">
            <div class="image">
              <bleed-container bleed="left">
                <div class="intro-image">
                  <img :src="page.elements.intro_image.value[0].url" />
                </div>

<!--                <div class="image-sizer-container">-->
<!--                  <div class="image-sizer"></div>-->
<!--                </div>-->
              </bleed-container>
            </div>

            <div class="login-promo-container">
              <div class="login-promo-box">
                <div class="heading uppercase">{{page.elements.intro_heading.value}}</div>

                <div class="description relative">
                  <div v-html="page.elements.intro_copy.value"></div>

                  <div class="sideways absolute transform -rotate-90 text-10 uppercase">
                    Expo / Discover / Conversations
                  </div>

                  <div class="cta flex flex-row space-x-8">
                    <button type="button" @click="register" class="btn-white flex-1">Sign Up</button>
                    <button type="button" @click="login" class="btn-transparent flex-1">Login</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section section2">
        <div class="grey-box">
          <div class="outer-container">
            <div class="inner-container">
              <div class="intro-text" v-html="page.elements.promo_copy.value"></div>
            </div>
          </div>
        </div>
        <div class="outer-container">
          <div class="inner-container">
            <bleed-container bleed="right">
              <div class="bleed-wrapper relative hidden md:block z-20">
                <div class="vertical-image relative">
                  <img :src="page.elements.promo_vertical_image.value[0].url" alt="" class="absolute right-0 bottom-0">
                </div>
              </div>
              <div class="horizontal-image relative z-10">
                <img :src="page.elements.promo_horizontal_image.value[0].url" alt="">
              </div>
            </bleed-container>
          </div>
        </div>
      </div>

      <div class="section section2b">
        <div class="outer-container">
          <div class="inner-container">
            <div class="image">
              <bleed-container bleed="left">
                <div class="image-container">
                  <img :src="page.elements.expo_main_image.value[0].url" class="image1" />
                </div>
                <div class="image-sizer-container">
                  <div class="image-sizer"></div>
                </div>
                <div class="bleed-wrapper">
                  <div class="heading absolute flex flex-row items-center justify-center">
                    <div class="letter letter1">E</div>
                    <div class="letter letter2">X</div>
                    <div class="letter letter3">P</div>
                    <div class="letter letter4">O</div>
                  </div>
                </div>
              </bleed-container>
            </div>

            <div class="copy" v-html="page.elements.expo_heading.value"></div>

            <div class="copy2" v-html="page.elements.expo_copy.value"></div>
          </div>
        </div>
      </div>

      <div class="section section3">
        <div class="outer-container">
          <div class="inner-container">
            <div class="image">
              <bleed-container bleed="left">
                <div class="image-container">
                  <img :src="page.elements.explore_main_image.value[0].url" class="image1" />
                  <img :src="page.elements.explore_vertical_image.value[0].url" class="image2" />
                </div>
                <div class="image-sizer-container">
                  <div class="image-sizer"></div>
                </div>
                <div class="bleed-wrapper">
                  <div class="heading absolute flex flex-row items-center justify-center">
                    <div class="letter letter1">E</div>
                    <div class="letter letter2">X</div>
                    <div class="letter letter3">P</div>
                    <div class="letter letter4">L</div>
                    <div class="letter letter5">O</div>
                    <div class="letter letter6">R</div>
                    <div class="letter letter7">E</div>
                  </div>
                </div>
              </bleed-container>
            </div>

            <div class="copy" v-html="page.elements.explore_heading.value"></div>

            <div class="copy2" v-html="page.elements.explore_copy.value"></div>
          </div>
        </div>
      </div>


      <div class="section section4">
        <div class="outer-container">
          <div class="inner-container">
            <div class="map">
              <img :src="page.elements.educate_right_image.value[0].url" />
            </div>
            <div class="image">
              <bleed-container bleed="left">
                <img :src="page.elements.educate_main_image.value[0].url" class="image1" />
                <div class="image-sizer-container">
                  <div class="image-sizer"></div>
                </div>
                <div class="bleed-wrapper">
                  <div class="heading absolute flex flex-row items-center justify-center">
                    <div class="letter letter1">E</div>
                    <div class="letter letter2">D</div>
                    <div class="letter letter3">U</div>
                    <div class="letter letter4">C</div>
                    <div class="letter letter5">A</div>
                    <div class="letter letter6">T</div>
                    <div class="letter letter7">I</div>
                    <div class="letter letter8">O</div>
                    <div class="letter letter9">N</div>
                  </div>
                </div>
              </bleed-container>
            </div>

            <div class="copy" v-html="page.elements.educate_heading.value"></div>

            <div class="copy2" v-html="page.elements.educate_copy.value"></div>
          </div>
        </div>
      </div>

      <div class="section section5">
        <div class="images">
          <div class="image1">
            <img :src="page.elements.register_vertical_image.value[0].url" alt="">
          </div>
<!--          <div class="image2">-->
<!--            <img :src="page.elements.register_main_image.value[0].url" alt="">-->
<!--          </div>-->
        </div>
        <div class="white-section">
          <div class="outer-container">
            <div class="inner-container">
              <div class="heading uppercase">
                {{page.elements.register_heading.value}}
              </div>

              <div class="description">
                <div v-html="page.elements.register_copy.value"></div>

                <div class="cta flex flex-row space-x-8">
                  <button type="button" @click="register" class="btn-black flex-1">Sign Up</button>
                  <button type="button" @click="login" class="btn-white-on-white flex-1">Login</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading flex flex-row items-center justify-center fixed left-0 top-0 w-full h-full z-100 bg-black bg-opacity-90" v-if="loading">
      <loading-spinner message="Sending verification email" />
    </div>
  </div>
</template>

<script>
import Analytics from '@/analytics'
import Vue from 'vue'
import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import BleedContainer from "@/components/BleedContainer";
import Kontent from "@/kontent";
import api from "@/api";
import LoadingSpinner from "@/components/LoadingSpinner";
import auth0 from "auth0-js";

gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'Home',
  metaInfo () {
    if (!this.page) {
      return null
    }

    let meta = [
      {property: 'og:site_name', content: 'CONNECT'},
      {property: 'og:url', content: window.location.href},
      {property: 'og:title', content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value},
      {property: 'og:description', content: this.page.elements.page__page_description.value},
      {name: 'title', content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value},
      {name: 'keywords', content: this.page.elements.page__meta_keywords.value},
      {name: 'description', content: this.page.elements.page__meta_description.value.length ? this.page.elements.page__meta_description.value : this.page.elements.page__page_description.value},
      {name: 'twitter:card', content: 'summary_large_image'},
      {name: 'twitter:title', content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value},
      {name: 'twitter:description', content: this.page.elements.page__meta_description.value.length ? this.page.elements.page__meta_description.value : this.page.elements.page__page_description.value}
    ]

    // if there's an image
    if (this.page.elements.page__main_image.value.length) {
      meta.push({property: 'og:image', content: this.page.elements.page__main_image.value[0].url})
      meta.push({name: 'twitter:image', content: this.page.elements.page__main_image.value[0].url})
    }

    // if there's a type
    if (this.page.elements.page__page_type.value.length) {
      meta.push({property: 'og:type', content: this.page.elements.page__page_type.value[0].name})
    }

    return {
      title: this.page.elements.page__page_title.value,
      meta: meta
    }
  },
  components: {LoadingSpinner, BleedContainer},
  methods: {
    resendVerificationEmail() {
      this.loading = true;
      api.sendVerificationEmail().then((res) => {
        if (res.data.success) {
          this.resent = true;
        }
        if (res.data.message) {
          this.responseMessage = res.data.message
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    login() {
      if (!this.$cookies.get('signup-source')) {
        this.$cookies.set('signup-source', 'Connect',null,'/');
      }
      this.$auth.loginWithRedirect({
        redirect_uri: `${window.origin}/home`
      });
    },
    register() {
      if (!this.$cookies.get('signup-source')) {
        this.$cookies.set('signup-source', 'Connect',null,'/');
      }
      this.$auth.loginWithRedirect({
        redirect_uri: `${window.origin}/home`,
        screen_hint: 'signup'
      });
    },
    initAnimation() {
      let s1 = gsap.timeline({
        scrollTrigger: {
          trigger: 'header',
          pin: false,
          start: 'top top',
          end: '+=1000',
          scrub: 1
        }
      });
      s1.addLabel('start')
          .to('header .main', {
            backgroundColor: '#000',
            duration: 1
          }, '<')


      let s2 = gsap.timeline({
        scrollTrigger: {
          trigger: '.section2',
          pin: false,
          start: 'top bottom',
          end: 'bottom center',
          scrub: 1
        }
      });
      s2.addLabel('start')
          .from('.section2 .intro-text', {
            x: 100,
            autoAlpha: 0,
            duration: 1
          })
          .from('.section2 .vertical-image', {
            y: '300px',
            duration: 1
          }, '<')
          .from('.section2 .horizontal-image', {
            x: '300px',
            duration: 1
          }, '<')


      // let w1 = gsap.timeline({
      //   scrollTrigger: {
      //     trigger: '.section2 .wine-tile',
      //     pin: false,
      //     start: 'top bottom',
      //     end: 'bottom top',
      //     scrub: 1
      //   }
      // });
      // w1.addLabel('start')
      //     .from('.section2 .wine-tile', {
      //       y: 100,
      //       autoAlpha: 0,
      //       duration: 1
      //     })
      //
      //
      // let w2 = gsap.timeline({
      //   scrollTrigger: {
      //     trigger: '.section2 .winery-tile',
      //     pin: false,
      //     start: 'top bottom',
      //     end: 'bottom top',
      //     scrub: 1
      //   }
      // });
      // w2.addLabel('start')
      //     .from('.section2 .winery-tile', {
      //       x: -100,
      //       autoAlpha: 0,
      //       duration: 1
      //     })


      // let s3 = gsap.timeline({
      //   scrollTrigger: {
      //     trigger: '.section2 .expo',
      //     pin: false,
      //     start: 'top bottom',
      //     end: 'bottom center',
      //     scrub: 1
      //   }
      // });
      //
      // s3.addLabel('start')
      //     .from('.section2 .ex1', {
      //       x: 200,
      //       autoAlpha: 0,
      //       duration: 1
      //     }, '<')
      //     .from('.section2 .ex2', {
      //       x: 200,
      //       autoAlpha: 0,
      //       duration: 1
      //     }, '+=0.3')
      //     .from('.section2 .ex3', {
      //       x: 200,
      //       autoAlpha: 0,
      //       duration: 1
      //     }, '+=0.3')

      let s3b = gsap.timeline({
        scrollTrigger: {
          trigger: '.section2b .image1',
          pin: false,
          start: 'top bottom',
          end: 'top top',
          scrub: 1
        }
      });

      s3b.addLabel('start')
          .from('.section2b .image1', {
            x: '-10%',
            // autoAlpha: 0,
            duration: 1
          })
          .to('.section2b .letter1', {
            x: '-80%',
            // y: '10%',
            // autoAlpha: 0,
            duration: 1
          }, '<0.3')
          .to('.section2b .letter2', {
            x: '-50%',
            // y: '50%',
            // autoAlpha: 0,
            duration: 1
          }, '<')
          .to('.section2b .letter3', {
            x: '50%',
            // y: '-30%',
            // autoAlpha: 0,
            duration: 1
          }, '<')
          .to('.section2b .letter4', {
            x: '60%',
            // y: '-50%',
            // autoAlpha: 0,
            duration: 1
          }, '<')


      let s5b = gsap.timeline({
        scrollTrigger: {
          trigger: '.section2b .copy',
          pin: false,
          start: 'top bottom',
          end: 'top center',
          scrub: 1
        }
      });

      s5b.addLabel('start')
          .from('.section2b .copy', {
            y: 200,
            autoAlpha: 0,
            duration: 1
          })

      let s6b = gsap.timeline({
        scrollTrigger: {
          trigger: '.section2b .copy2',
          pin: false,
          start: 'top bottom',
          end: 'top center',
          scrub: 1
        }
      });

      s6b.addLabel('start')
          .from('.section2b .copy2', {
            y: 200,
            autoAlpha: 0,
            duration: 1
          })

      let s4 = gsap.timeline({
        scrollTrigger: {
          trigger: '.section3 .image1',
          pin: false,
          start: 'top bottom',
          end: 'top top',
          scrub: 1
        }
      });

      s4.addLabel('start')
          .from('.section3 .image1', {
            x: '-10%',
            // autoAlpha: 0,
            duration: 1
          })
          .to('.section3 .letter1', {
            x: '-50%',
            // y: '10%',
            // autoAlpha: 0,
            duration: 1
          }, '<0.3')
          .to('.section3 .letter2', {
            x: '50%',
            // y: '50%',
            // autoAlpha: 0,
            duration: 1
          }, '<')
          .to('.section3 .letter3', {
            x: '-30%',
            // y: '-30%',
            // autoAlpha: 0,
            duration: 1
          }, '<')
          .to('.section3 .letter4', {
            x: '-60%',
            // y: '-50%',
            // autoAlpha: 0,
            duration: 1
          }, '<')
          .to('.section3 .letter5', {
            x: '30%',
            // y: '50%',
            // autoAlpha: 0,
            duration: 1
          }, '<')
          .to('.section3 .letter6', {
            x: '50%',
            // y: '10%',
            // autoAlpha: 0,
            duration: 1
          }, '<')
          .to('.section3 .letter7', {
            x: '80%',
            // y: '80%',
            // autoAlpha: 0,
            duration: 1
          }, '<')

      let s4b = gsap.timeline({
        scrollTrigger: {
          trigger: '.section3 .image2',
          pin: false,
          start: 'top bottom',
          end: 'top center',
          scrub: 1
        }
      });

      s4b.addLabel('start')
          .from('.section3 .image2', {
            y: 200,
            // autoAlpha: 0,
            duration: 1
          })

      let s5 = gsap.timeline({
        scrollTrigger: {
          trigger: '.section3 .copy',
          pin: false,
          start: 'top bottom',
          end: 'top center',
          scrub: 1
        }
      });

      s5.addLabel('start')
          .from('.section3 .copy', {
            y: 200,
            autoAlpha: 0,
            duration: 1
          })

      let s6 = gsap.timeline({
        scrollTrigger: {
          trigger: '.section3 .copy2',
          pin: false,
          start: 'top bottom',
          end: 'top center',
          scrub: 1
        }
      });

      s6.addLabel('start')
          .from('.section3 .copy2', {
            y: 200,
            autoAlpha: 0,
            duration: 1
          })

      let s7 = gsap.timeline({
        scrollTrigger: {
          trigger: '.section4 .map',
          pin: false,
          start: 'top bottom',
          end: 'top center',
          scrub: 1
        }
      });

      s7.addLabel('start')
          .from('.section4 .map', {
            x: 200,
            // autoAlpha: 0,
            duration: 1
          })

      let s8 = gsap.timeline({
        scrollTrigger: {
          trigger: '.section4 .image1',
          pin: false,
          start: 'top bottom',
          end: 'top center',
          scrub: 1
        }
      });

      s8.addLabel('start')
          .from('.section4 .image1', {
            x: '-10%',
            // autoAlpha: 0,
            duration: 1
          })

      let s9 = gsap.timeline({
        scrollTrigger: {
          trigger: '.section4 .image1',
          pin: false,
          start: 'top center',
          end: 'bottom top',
          scrub: 1
        }
      });

      s9.addLabel('start')
          .to('.section4 .letter1', {
            x: '-50%',
            // y: '10%',
            // autoAlpha: 0,
            duration: 1
          }, '<0.3')
          .to('.section4 .letter2', {
            x: '-50%',
            // y: '50%',
            // autoAlpha: 0,
            duration: 1
          }, '<')
          .to('.section4 .letter3', {
            x: '50%',
            // y: '-30%',
            // autoAlpha: 0,
            duration: 1
          }, '<')
          .to('.section4 .letter4', {
            x: '-30%',
            // y: '-50%',
            // autoAlpha: 0,
            duration: 1
          }, '<')
          .to('.section4 .letter5', {
            x: '50%',
            // y: '50%',
            // autoAlpha: 0,
            duration: 1
          }, '<')
          .to('.section4 .letter6', {
            x: '80%',
            // y: '10%',
            // autoAlpha: 0,
            duration: 1
          }, '<')
          .to('.section4 .letter7', {
            x: '50%',
            // y: '80%',
            // autoAlpha: 0,
            duration: 1
          }, '<')
          .to('.section4 .letter8', {
            x: '-30%',
            // y: '-50%',
            // autoAlpha: 0,
            duration: 1
          }, '<')
          .to('.section4 .letter9', {
            x: '-10%',
            // y: '30%',
            // autoAlpha: 0,
            duration: 1
          }, '<')

      let s10 = gsap.timeline({
        scrollTrigger: {
          trigger: '.section4 .copy',
          pin: false,
          start: 'top bottom',
          end: 'top center',
          scrub: 1
        }
      });

      s10.addLabel('start')
          .from('.section4 .copy', {
            y: 200,
            autoAlpha: 0,
            duration: 1
          })

      let s11 = gsap.timeline({
        scrollTrigger: {
          trigger: '.section4 .copy2',
          pin: false,
          start: 'top bottom',
          end: 'top center',
          scrub: 1
        }
      });

      s11.addLabel('start')
          .from('.section4 .copy2', {
            y: 200,
            autoAlpha: 0,
            duration: 1
          })

      let s12 = gsap.timeline({
        scrollTrigger: {
          trigger: '.section5 .image1',
          pin: false,
          start: 'top bottom',
          end: 'top center',
          scrub: 1
        }
      });

      s12.addLabel('start')
          .from('.section5 .image1', {
            y: 200,
            // autoAlpha: 0,
            duration: 1
          })

      // let s13 = gsap.timeline({
      //   scrollTrigger: {
      //     trigger: '.section5 .image2',
      //     pin: false,
      //     start: 'top bottom',
      //     end: 'top center',
      //     scrub: 1
      //   }
      // });
      //
      // s13.addLabel('start')
      //     .from('.section5 .image2', {
      //       x: '100%',
      //       autoAlpha: 0,
      //       duration: 1
      //     })
      //     .from('.section5 .white-section', {
      //       y: '200px',
      //       autoAlpha: 0,
      //       duration: 1
      //     })
    }
  },
  data() {
    return {
      page: null,
      verifyClosed: false,
      loading: false,
      resent: false,
      responseMessage: null
    }
  },
  mounted() {
    Analytics.trackPage('Home')

    // if (this.$auth.isAuthenticated) {
    //   console.log('authenticated - redirect')
    //   this.$router.push('/home')
    // }

    const self = this
    Kontent.getItemByType('landing_page').then((res) => {
      this.page = res.data.items[0]

      setTimeout(function(){window.prerenderReady = true}, 1000)
      Vue.nextTick(function () {
        self.initAnimation()
      })
    })
  },
  updated() {
    if (this.$route.path === '/verify-email' && !this.verifyClosed && this.$route.query.state) {
      // For the 'verify email' screen, save the state query string value
      // on the user profile. This allows us to continue the login process
      // when the user verifies their email.
      this.$auth.getTokenSilently().then((token) => {
        const auth0Manage = new auth0.Management({
          domain: process.env.VUE_APP_AUTH_DOMAIN,
          token
        });
        auth0Manage.patchUserMetadata(this.$auth.user.sub, {
          auth_state: this.$route.query.state
        }, () => {});
      });
    }
  }
}
</script>

<style lang="scss">
.new-landing .section1 {
  .bleed-content {
    overflow: hidden;
  }
}
</style>

<style scoped lang="scss">

.verify-overlay {
  a {
    color: #FFF;
    text-decoration: underline;
  }
}

//.section-container {
//  height: 500vh;
//}

.sideways {
  left: -50px;
  top: 80%;
  transform-origin: left;
  color: #FFF;

  @media(min-width: 1024px)
  {
    left: 0;
  }
}

.new-landing {
  line-height: 1.4;
}

.section {
  overflow: hidden;

  &.section1 {
    overflow: visible;
  }
}

.section1 {

  .intro-image {
    height: 150px;
    @media(min-width: 768px)
    {
      height: 450px;
    }

  }

  padding-bottom: 150px;
  .image {
    width: 60%;
    position: relative;
    z-index: 1;
    margin-top: -50px;
    @media(min-width: 768px)
    {
      width: 70%;
      margin-top: -150px;
    }

    .image-sizer-container {
      max-width: 1200px;
      width: 100%;
    }

    .image-sizer {
      padding-bottom: 56%;
      position: relative;
    }

    img {
      display: block;
      width: 100%;
      max-width: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .login-promo-container {
    margin-top: -30px;
    position: relative;
    z-index: 2;
    padding-left: 30px;

    @media(min-width: 768px)
    {
      margin-top: -80px;
      padding-left: 20%;
    }

    .heading {
      font-size: 24px;
      font-weight: bold;
      line-height: 1.1;

      @media(min-width: 768px)
      {
        font-size: 40px;
      }

      @media(min-width: 1024px)
      {
        font-size: 56px;
      }

      @media(min-width: 1500px)
      {
        font-size: 68px;
      }
    }

    .description {
      color: #999;
      line-height: 2;
      margin-top: 30px;

      @media(min-width: 1024px)
      {
        margin-top: 60px;
        padding-left: 20%;
        font-size: 20px;
      }
    }

    .cta {
      line-height: 1;

      margin-top: 30px;

      @media(min-width: 768px)
      {
        margin-top: 60px;
      }
    }
  }

  .login-promo-box {
    background-color: #000;
    padding: 20px;

    @media(min-width: 768px)
    {
      padding: 40px;
    }
  }
}

.section2 {
  padding-top: 100px;
  padding-bottom: 150px;
  margin-top: -150px;

  .grey-box {
    background-color: #111;
    padding-top: 40px;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 100px;
    padding-left: 10px;
    padding-right: 10px;

    @media(min-width: 768px)
    {
      padding-top: 60px;
      padding-left: 10%;
      padding-right: 40%;
      padding-bottom: 200px;
      font-size: 30px;
    }

    @media(min-width: 1500px)
    {
      padding-top: 200px;
      padding-bottom: 200px;
      font-size: 40px;
      padding-right: 60%;
    }
  }

  .vertical-image {
    width: 30%;
    margin-left: auto;
    top: 50px;

    @media(min-width: 1024px)
    {
      width: 20%;
    }

    @media(min-width: 1500px) {
      width: 25%;
    }
  }

  .horizontal-image {
    width: 80%;
    margin-left: auto;

    @media(min-width: 1024px)
    {
      width: 60%;
    }

    img {
      margin-top: -60px;

      @media(min-width: 768px)
      {
        margin-top: -150px;
      }
    }

  }

}

.section2b {
  padding-top: 150px;
  padding-bottom: 150px;
  margin-top: -150px;

  .image {
    width: 50%;

    @media(min-width: 768px)
    {
      width: 40%;
    }

    .image2 {
      width: 50%;
      left: 25%;
      position: absolute;
      top: 70%;
      display: none;
      @media(min-width: 768px)
      {
        display: block;
      }
    }
  }

  .heading {
    font-weight: 600;
    font-size: 30px;
    @media(min-width: 768px)
    {
      font-size: 60px;
    }
    @media(min-width: 1024px)
    {
      font-size: 70px;
    }
    @media(min-width: 1500px)
    {
      font-size: 76px;
    }

    position: absolute;
    top: 5%;
    left: 40%;
  }

  .letter {
    margin-right: 0.5em;
  }

  //.letter2 {
  //  margin-right: 1em;
  //}
  //
  //.letter4 {
  //  margin-right: 0.8em;
  //}
  //
  //.letter6 {
  //  margin-right: 1.2em;
  //}

  .copy {
    font-size: 20px;
    font-weight: bold;
    padding-left: 20%;
    padding-right: 10px;
    margin-top: 20px;

    @media(min-width: 768px)
    {
      font-size: 30px;

      padding-left: 45%;
      margin-top: -40px;
    }

    @media(min-width: 1024px)
    {
      margin-top: -60px;
    }

    @media(min-width: 1500px)
    {
      font-size: 50px;
    }
  }

  .copy2 {
    padding-left: 20%;
    padding-right: 10px;
    margin-top: 20px;
    color: #999;

    @media(min-width: 768px)
    {
      padding-left: 45%;
    }

    @media(min-width: 1500px)
    {
      font-size: 20px;
    }
  }
}

.section3 {
  padding-top: 150px;
  padding-bottom: 150px;
  margin-top: -150px;

  .image {
    width: 50%;

    @media(min-width: 768px)
    {
      width: 45%;
    }

    .image2 {
      width: 50%;
      left: 25%;
      position: absolute;
      top: 70%;
      display: none;
      @media(min-width: 768px)
      {
        display: block;
      }
    }
  }

  .heading {
    font-weight: 600;
    font-size: 30px;
    @media(min-width: 768px)
    {
      font-size: 60px;
    }
    @media(min-width: 1024px)
    {
      font-size: 70px;
    }
    @media(min-width: 1500px)
    {
      font-size: 76px;
    }

    position: absolute;
    top: 5%;
  }

  .letter {
    margin-right: 0.5em;
  }

  //.letter2 {
  //  margin-right: 1em;
  //}
  //
  //.letter4 {
  //  margin-right: 0.8em;
  //}
  //
  //.letter6 {
  //  margin-right: 1.2em;
  //}

  .copy {
    font-size: 20px;
    font-weight: bold;
    padding-left: 20%;
    padding-right: 10px;
    margin-top: 20px;

    @media(min-width: 768px)
    {
      font-size: 30px;

      padding-left: 55%;
      margin-top: -80px;
    }

    @media(min-width: 1500px)
    {
      font-size: 50px;
    }
  }

  .copy2 {
    padding-left: 20%;
    padding-right: 10px;
    margin-top: 20px;
    color: #999;

    @media(min-width: 768px)
    {
      padding-left: 55%;
    }

    @media(min-width: 1500px)
    {
      font-size: 20px;
    }
  }
}

.section4 {
  padding-top: 150px;
  padding-bottom: 150px;

  margin-top: -150px;

  .map {
    text-align: right;

    img {
      display: inline-block;
      width: 60%;

      @media(min-width: 768px)
      {
        width: 50%;
      }
    }
  }

  .image {
    width: 50%;
    margin-top: -10%;

    @media(min-width: 768px) {
      width: 45%;
    }
  }

  .heading {
    font-weight: 600;
    font-size: 30px;
    @media(min-width: 768px)
    {
      font-size: 60px;
    }
    @media(min-width: 1024px)
    {
      font-size: 70px;
    }
    @media(min-width: 1500px)
    {
      font-size: 76px;
    }

    position: absolute;
    bottom: -10%;
  }

  .letter {
    margin-right: 0.5em;
  }

  //.letter2 {
  //  margin-right: 1.2em;
  //}
  //
  //.letter4 {
  //  margin-right: 0.8em;
  //}
  //
  //.letter6 {
  //  margin-right: 1.6em;
  //}

  .copy {
    font-size: 20px;
    font-weight: bold;
    padding-right: 20%;
    padding-left: 20px;

    margin-top: 40px;

    @media(min-width: 768px)
    {
      font-size: 30px;

      padding-right: 55%;
    }

    @media(min-width: 1500px)
    {
      font-size: 50px;
      margin-top: 80px;
    }
  }

  .copy2 {
    padding-right: 20%;
    padding-left: 20px;
    margin-top: 20px;
    color: #999;

    @media(min-width: 768px)
    {
      padding-right: 55%;
    }

    @media(min-width: 1500px)
    {
      font-size: 20px;
    }
  }
}

.section5 {
  padding-top: 100px;
  margin-top: -150px;

  .description {
    padding-left: 10%;
    padding-right: 30%;
    margin-top: 30px;

    @media(min-width: 768px)
    {
      margin-top: 50px;
      padding-left: 20%;
    }

    @media(min-width: 1024px)
    {
      margin-top: 60px;
      font-size: 20px;
    }
  }

  .cta {
    line-height: 1;

    margin-top: 30px;

    @media(min-width: 768px)
    {
      margin-top: 60px;
    }
  }

  .images {
    text-align: right;
    padding-left: 30%;
    position: relative;
    z-index: 2;

    @media(min-width: 768px)
    {
      padding-left: 50%;
    }

    img {
      display: inline-block;
    }

    .image1 {
      padding-left: 25%;
      padding-right: 25%;
      position: relative;
      z-index: 2;
    }

    .image2 {
      position: relative;
      z-index: 1;
      margin-top: -20%;
    }
  }

  .white-section {
    position: relative;
    z-index: 1;
    background-color: #fff;
    margin-top: -50px;
    color: #000;
    padding: 80px 0;

    @media(min-width: 768px)
    {
      padding: 100px 0;
      margin-top: -100px;
    }

    @media(min-width: 768px)
    {
      padding: 130px 0;
      margin-top: -220px;
    }
  }

  .heading {

    padding-left: 10%;
    color: #000;

    @media(min-width: 768px)
    {
      padding-right: 55%;
    }

    font-size: 24px;
    font-weight: bold;
    line-height: 1.1;

    @media(min-width: 768px)
    {
      font-size: 40px;
    }

    @media(min-width: 1024px)
    {
      font-size: 56px;
    }

    @media(min-width: 1500px)
    {
      font-size: 76px;
    }
  }
}
</style>
